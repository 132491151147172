<template>
  <v-container fluid>
    <v-data-iterator
      :items="cases"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      no-data-text=""
      no-results-text=""
      hide-default-footer
    >
      <template v-slot:header>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <v-toolbar
              color="secondary"
              rounded
              flat
              class="mb-1"
            >
              <v-toolbar-title class="white--text">Published Cases</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer />
              <v-text-field
                v-model="search"
                clearable
                flat
                solo
                dense
                rounded
                class="d-none d-sm-flex"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer />
              <v-btn
                small
                class="ms-2 primary"
                rounded
                :to="{ name: 'case-edit', params: { id: 'new' } }"
                >New Case</v-btn
              >
            </v-toolbar>
          </v-col>
        </v-row>
        <v-card-title class="d-flex d-sm-none align-center flex-wrap">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo
            outlined
            dense
            rounded
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
        </v-card-title>
      </template>

      <template v-slot:default="{ items, isLoading }">
        <v-row>
          <template v-if="isLoading">
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              v-for="n in itemsPerPage"
              :key="n"
            >
              <v-skeleton-loader type="card" class="mb-4"></v-skeleton-loader>
            </v-col>
          </template>

          <template v-else>
            <v-col
              v-for="caseItem in items"
              :key="caseItem.id"
              cols="12"
              md="8"
              offset-md="2"
            >
            <v-card style="min-height: 100%;" class="justify-center position-relative" elevation="3">
                <!-- Background image div -->
                <v-img
                  v-if="caseImage"
                  alt="Protected"
                  :src="caseImage"
                  contain
                  cover
                  no-repeat
                  position="right"
                  :style="{
                    opacity: 0.5,
                    position: 'absolute',
                    top: '10%',
                    right: '2%',
                    bottom: '10%',
                    zIndex: 0,
                  }"
                ></v-img>
                <v-chip
                  label
                  small
                  color="secondary"
                  class="text-overline ml-2 my-2"
                >
                  {{ caseItem.caseId }}
                </v-chip>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ caseItem.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Author: {{ caseItem.authorName }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Publisher: {{ caseItem.publisherName }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="caseItem.editorName">Editor: {{ caseItem.editorName }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-3"
                        fab
                        small
                        color="primary"
                        elevation="7"
                        raised
                        v-bind="attrs"
                        v-on="on"
                        :to="{ name: 'case', params: { id: caseItem.id } }"
                      >
                        <v-icon small>mdi-book-open-page-variant-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Open Case</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-3"
                        fab
                        small
                        color="primary"
                        elevation="7"
                        raised
                        v-bind="attrs"
                        v-on="on"
                        :to="{ name: 'case-edit', params: { id: caseItem.id } }"
                      >
                        <v-icon small>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Case</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-6"
                        fab
                        small
                        color="primary"
                        elevation="7"
                        raised
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteCase(caseItem)"
                      >
                        <v-icon small>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Case</span>
                  </v-tooltip>

                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </template>

      <template v-slot:no-results>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No matching cases found"
          description="Try a different search term."
        />
      </template>

      <template v-slot:no-data>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No case has been published"
          description="Your cases collection is currently empty."
        />
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-col cols="12" md="8">
            <v-row align="center" justify="center">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
                :total-visible="5"
                circle
                color="primary"
              ></v-pagination>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <!-- Delete Confirmation Modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card outlined>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to delete <strong class="primary--text">"{{ caseItem.caseId }}"</strong> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" small @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { casesCollection, db } from "../../firebaseDb";
import firebase from "firebase/app";
import axios from 'axios';
import useLocalStorage from '@/composables/useLocalStorage';
import VEmptyState from '@/components/VEmptyState.vue';

export default {
  data() {
    return {
      // snackbar data
      snackbar: false,
      response: "",
      snackColor: "black",
      search: "",
      page: 1,
      itemsPerPage: 10,
      itemsPerPageArray: [10, 20, 30],
      caseImage: null,
      caseItem: "",
      deleteDialog: false,
    };
  },
  components: {
    VEmptyState,
  },
  computed: {
    ...mapState(["currentUser", "cases", "settings"]),
    ...mapGetters(["darkTheme"]),
    filteredCases() {
      return this.cases.filter((caseItem) => {
        const title = caseItem.title ? caseItem.title.toLowerCase() : '';
        const caseId = caseItem.caseId ? caseItem.caseId.toLowerCase() : '';
        const search = this.search ? this.search.toLowerCase() : '';
        return title.includes(search) || caseId.includes(search);
      });
    },
    paginatedCases() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = this.page * this.itemsPerPage;
      return this.filteredCases.slice(start, end);
    },
    numberOfPages() {
      return Math.ceil(this.filteredCases.length / this.itemsPerPage);
    },
  },
  watch: {
    search() {
        this.page = 1;
    },
    selectedCategory() {
        this.page = 1;
    },
  },
  methods: {
    printOut(item) {
      console.log(item);
    },
    deleteCase(item) {
      // console.log("Case to delete: ", item);
      this.caseItem = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      let item = this.caseItem;
      casesCollection
        .doc(item.id)
        .get()
        .then((c) => {
          const folderPath = "fulltext/" + item.id;

          axios.delete(`${process.env.VUE_APP_API_BASE_URL}/deleteFile.php?folderPath=${folderPath}`)
            .then((response) => {
              console.log('Files deleted successfully:', response.data);
            })
            .catch((error) => {
              console.error('Error deleting files:', error);
            });

          c.data().issues.forEach((i) => {
            i.get().then((iss) => {
              if (typeof iss.data().related != "undefined") {
                iss
                  .data()
                  .related.get()
                  .then((rel) => {
                    let newLinks = rel.data();
                    newLinks.links.splice(
                      newLinks.links.findIndex((x) => x.issueRef == iss.id),
                      1
                    );
                    let unique = newLinks.links.filter(
                      (value, index, self) => {
                        return (
                          self.findIndex(
                            (v) => v.caseRef === value.caseRef
                          ) === index
                        );
                      }
                    );
                    if (unique.length == 1) {
                      newLinks.links.forEach((a) => {
                        db.doc("issues/" + a.issueRef).update({
                          related: firebase.firestore.FieldValue.delete(),
                        });
                      });
                      iss.data().related.delete();
                    } else {
                      rel.ref.update(newLinks);
                    }
                  });
              }
              i.delete();
            });
          });
          c.ref.delete();
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "Case successfully deleted!";
        })
        .catch((error) => {
          console.error(error);
          this.snackbar = true;
          this.snackColor = "fail";
          this.response = error;
        });
      this.caseItem = ""
      this.deleteDialog = false;
    },
  },
  created() {
    this.$store.dispatch("streamCaseLists");
    if (this.settings.length == 0) {
      this.$store.dispatch("fetchSettings");
    }
    const caseImageStorage = useLocalStorage(null, "caseImage");
    this.caseImage = caseImageStorage.get();
  },
  beforeDestroy() {
    this.$store.dispatch("detachCaseList");
  },
};
</script>

<style>
.btn-primary {
  margin-right: 12px;
}
.fill-height {
  height: 100%;
}
.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}

.no-cases-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
</style>
